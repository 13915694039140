<template>
  <transition name="fade">
    <loading-spinner v-if="authenticatingUser"></loading-spinner>
    <div v-else class="Dashboard">
      <the-top-nav></the-top-nav>
      <div style="display: flex; width: 100vw; overflow: auto; position: relative;">
        <the-side-nav></the-side-nav>
        <router-view class="m-container" :class="{ 'overflow-hidden': stop_scrolling }">
        </router-view>
      </div>
      <notifications-stack></notifications-stack>
      <main-modal v-if="isTherModal" @close="showModal = false"></main-modal>
    </div>
  </transition>
</template>

<script>
import TheTopNav from "../components/layout componenets/TopNav";
import TheSideNav from "../components/layout componenets/SideNave";
import notificationsStack from "../components/notifications/notificationStack";
import mainModal from "../components/notifications/modal";
import { EventBus } from "./../utils/EventBus";
export default {
  name: "Dashboard",
  components: {
    "the-top-nav": TheTopNav,
    "the-side-nav": TheSideNav,
    // pageValidity,
    notificationsStack,
    mainModal
  },
  data() {
    return {
      stop_scrolling: false,
      showModal: true
    };
  },
  created() {
    EventBus.$on("MODAL_OPENED", () => {
      this.stop_scrolling = true;
    });
    EventBus.$on("MODAL_CLOSED", () => {
      this.stop_scrolling = false;
    });
    if (this.$route.name === "dashboard") {
      this.$router.push({ name: "Commenter.NewCommenter" }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.$store.dispatch("windowWidth/setWindowWidth", window.innerWidth);
    window.addEventListener("resize", event => {
      this.$store.dispatch("windowWidth/setWindowWidth", window.innerWidth);
    });
  },
  computed: {
    authenticatingUser() {
      return this.$store.getters["authenticatingUser"];
    },
    isTherModal() {
      return this.showModal && this.$store.getters["page/modal"] != null;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/vue-multiselect";

.overflow-hidden {
  overflow: hidden;
}

.Dashboard {
  display: block;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.m-container {
  height: calc(100vh - 50px);
}

.loading-screen {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer {
  height: 50px;
}

.validation-element {
  user-select: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 50;
  padding: 20px;
  background: rgb(0, 122, 254);
  background: linear-gradient(
    180deg,
    rgba(0, 122, 254, 0.8995798148360907) 0%,
    rgba(0, 122, 254, 0.8995798148360907) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.dashboard-footer {
  height: 30px;
}

/* @media only screen and (max-width: 550px) {
    .control-section {
      width: 100vw;
    }
  } */
</style>
