<template>
  <div class="top-nav">
    <div class="m-burger unselectable" @click="clicked">
      <font-awesome-icon icon="bars" class="unselectable" />
    </div>
    <span class="spacer"></span>

    <div class="settings">
      <a id="settings_trigger" href="#" @click="showSettings">
        <font-awesome-icon icon="th" />
      </a>
      <ul
        id="pageSettings_12uha"
        class="dropMenu settings-dropdown-content rb-s"
        v-bind:class="{ show: settings }"
      >
        <li
          v-for="item in settingsItems"
          @click.stop="goTo(item.href)"
          :key="item.text"
          active-class=""
        >
          {{ item.text }}
          <font-awesome-icon :icon="item.icon" />
        </li>
      </ul>
    </div>

    <div class="m-dropdown" :class="{ 'has-notification': notifications.length > 0 }">
      <img id="userSettings_trigger" class="avatar" @click="showInfo" :src="userPicture" />
      <ul
        id="userSettings_12uha"
        class="dropMenu user-dropdown-content rb-s"
        :class="{ show: info }"
      >
        <li
          v-for="item in infoItems.slice(0, 2)"
          @click.stop="goTo(item.href)"
          :key="item.text"
          active-class=""
          :class="{
            'has-notification': notifications.length && item.href === 'Dashboard.Notifications'
          }"
        >
          {{ item.text }}
          <font-awesome-icon :icon="item.icon" />
        </li>
        <li @click="logout">
          {{ infoItems[2].text }}
          <font-awesome-icon icon="sign-out-alt" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../utils/EventBus";
export default {
  data() {
    return {
      info: false,
      settings: false,
      infoItems: [
        { href: "Dashboard.Control", icon: "cog", text: "تحكم" },
        { href: "Dashboard.Notifications", icon: "exclamation-circle", text: "نتبيهات" },
        { href: "/dashboard/logout", icon: "sign-out-alt", text: "تسجيل خروج" }
      ],
      settingsItems: [
        { href: "Dashboard.Manage", icon: "flag", text: "إدارة الصفحات" },
        { href: "Dashboard.Documentation", icon: "life-ring", text: "مساعدة" }
      ]
    };
  },
  methods: {
    showSettings() {
      this.settings = !this.settings;
      if (this.info) {
        this.info = false;
      }
    },
    showInfo() {
      this.info = !this.info;
      if (this.settings) {
        this.settings = false;
      }
    },
    clicked() {
      EventBus.$emit("showSideNavBar");
    },
    goTo: function(to) {
      this.$router.push({ name: to });
      if (this.settings) {
        this.settings = false;
      }
      if (this.info) {
        this.info = false;
      }
    },
    logout() {
      this.$auth.logOut();
    },
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    clickOutSideSubMenu(event) {
      const menue = document.getElementById("pageSettings_12uha");
      const menuTrigger = document.getElementById("settings_trigger");

      const userMenu = document.getElementById("userSettings_12uha");
      const userMenuTrigger = document.getElementById("userSettings_trigger");

      if (
        this.isVisible(menue) &&
        !menue.contains(event.target) &&
        !menuTrigger.contains(event.target)
      ) {
        this.settings = false;
        // console.log("hi hi hi")
      }
      if (
        this.isVisible(userMenu) &&
        !userMenu.contains(event.target) &&
        !userMenuTrigger.contains(event.target)
      ) {
        this.info = false;
      }
    }
  },
  computed: {
    userPicture() {
      return this.$store.getters["user/picture"];
    },
    notifications() {
      return this.$store.getters["notifications/notifications"] || [];
    }
  },
  mounted() {
    document.addEventListener("click", this.clickOutSideSubMenu);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSideSubMenu);
  }
};
</script>

<style scoped lang="scss">
.top-nav {
  /* position: fixed; */
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-column: 1 / span 2;
  grid-row: 1;
  align-items: center;
  align-content: center;
  background: #fff;
  font-size: 33px;
  z-index: 1000;
  border-bottom: 1px solid #eef1f6;
  a {
    color: #007afe;
    transition: color 0.1s ease-in-out;
    &:hover {
      color: #008dcc;
    }
  }
  .m-burger {
    color: #007afe;
    width: 58px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    display: none;
    font-size: 25px;
    i {
      margin: 0;
    }
  }
  .m-dropdown,
  .settings {
    margin-right: 30px;
    display: flex;
    position: relative;
    align-items: center;
    align-content: center;
    height: 100%;
    /* z-index: 1003; */
    user-select: none;
  }
  .settings {
    font-size: 25px;
    align-self: center;
    cursor: pointer;
    user-select: none;
    .settings-dropdown-content {
      position: absolute;
      background: white;
      display: none;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      top: 110%;
      right: 0;
      z-index: 1100;
      width: 190px;
      cursor: pointer;
      &.show {
        display: block;
      }
      li {
        border: none;
        text-align: right;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-size: 15px;
        color: #007afe;
        border-bottom: 1px solid #eef1f6;
        transition: background 0.1s ease-in-out;
        width: 100%;
        text-align: right;
        cursor: pointer;
        background: white;
        &:hover {
          background: #f7f7f7fa;
        }
      }
      svg {
        margin-left: 10px;
        font-size: 19px;
        font-weight: bold;
      }
    }
  }
  .m-dropdown {
    .avatar {
      align-self: center;
      cursor: pointer;
      user-select: none;
      border-radius: 50%;
      width: 40px;
      height: auto;
      position: relative;
    }
    &.has-notification::after {
      content: "";
      position: absolute;
      display: block;
      top: 4px;
      background: #ff4348;
      width: 13px;
      height: 13px;
      border-radius: 50px;
      border: 2px solid white;
      button {
        color: red;
      }
    }
    .user-dropdown-content {
      position: absolute;
      background: white;
      display: none;
      width: 175px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      top: 110%;
      right: 0;
      z-index: 1100;
      cursor: pointer;
      &.show {
        display: block;
      }
      li {
        border: none;
        text-align: right;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-size: 15px;
        color: #007afe;
        border-bottom: 1px solid #eef1f6;
        transition: background 0.1s ease-in-out;
        cursor: pointer;
        width: 100%;
        text-align: right;
        background: white;
        &.has-notification {
          color: red;
        }
        &:hover {
          background: #f7f7f7fa;
        }
      }
      svg {
        margin-left: 10px;
        font-size: 19px;
        font-weight: bold;
      }
    }
  }
}
.dropMenu button.has-notification {
  color: #ff4348;
}

@media only screen and (max-width: 550px) {
  .m-burger {
    display: flex !important;
  }
}
</style>
