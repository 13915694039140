<template>
  <div v-if="notification.show" class="m-snackbar rounded" :class="[notification.type]">
    <button @click="gotTo" class="mujeeb-button rounded">شاهد</button>
    <span class="spacer"></span>
    <p class="m-snackbar__message">{{ title }}</p>
    <p class="close-button" @click="close"><font-awesome-icon icon="times" /></p>
  </div>
</template>

<script>
import { EventBus } from "../../utils/EventBus";
export default {
  props: {
    notification: {
      type: Object,
      default: function() {
        return {
          type: this.$store.getters.summaryType,
          url: "Dashboard.Notifications",
          title: "لديك تنبيهات جديدة",
          summary: true,
          show: true
        };
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    gotTo() {
      if (typeof this.notification.url !== "undefined" && this.notification.url !== "") {
        switch (this.notification.urlType) {
          case "URL":
            window.open(this.notification.url, "_blank");
            break;
          case "in-site-URL":
            EventBus.$emit("TAB_SELECTED", { to: this.notification.url });
            this.$router.push({ name: this.notification.url });
            break;
        }
        this.$store.dispatch("hideOneNotification", this.notification.id);
      } else {
        this.$router.push({ name: "Dashboard.Notifications" });
        this.$store.dispatch("hideAllNotifications");
      }
    },
    close() {
      if (typeof this.notification.summary !== "undefined" && this.notification.summary) {
        this.$store.dispatch("notifications/hideAllNotifications");
        this.notification.show = false;
      } else {
        this.$store
          .dispatch("notifications/hideOneNotification", this.notification.id)
          .then(({ data }) => {});
      }
    }
  },
  computed: {
    title() {
      if (this.notification.title.length > 17) {
        return this.notification.title.slice(0, 12) + "...";
      }
      return this.notification.title;
    }
  }
};
</script>

<style scoped>
.mujeeb-button {
  margin: 0;
  padding: 0 12px;
  color: white;
  white-space: nowrap;
  /* margin-right: 20px; */
}

.close-button {
  border-radius: 50%;
  background: white;
  color: rgb(109, 109, 109);
  width: 30px;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

i.icon,
.close-button {
  flex: 0 0 auto;
  cursor: pointer;
}
</style>
