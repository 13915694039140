<template>
  <div
    class="absolute top-0 bottom-0 right-0 left-0 bg-transparant-black flex justify-center items-center content-center"
  >
    <div class="bg-white p-4 rounded">
      <div v-html="modal.content"></div>
      <div>
        <button class="mujeeb-button green big" :disabled="submitting" @click="close">
          {{ modal.buttonName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    close() {
      this.submitting = true;
      this.$api
        .customRequest({
          method: "delete",
          url: `/services/notifications/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/modal`,
          responseType: "json"
        })
        .then(() => {
          console.log("done");
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.submitting = false;
          this.$emit("close");
        });
    }
  },
  computed: {
    modal() {
      return this.$store.getters["page/modal"];
    }
  }
};
</script>

<style lang="scss" scoped></style>
