<template>
  <div style="display: inline-block; position: relative;">
    <!-- <p>{{ items }}</p> -->
    <ul id="sideNav" class="m-side-nav" :class="{ showSideNav: showSidenav }">
      <li
        @click="onMainMenuOptionClicked(item)"
        v-for="(item, index) in computed_options"
        :class="{ selected: item.selected }"
        :key="index"
      >
        <font-awesome-icon v-if="item.icon !== 'icon-mujeeb-logo'" :icon="item.icon" />
        <i v-else class="icon" :class="item.icon"></i>
        <span class="feature-name">{{ item.text }}</span>
      </li>
      <div class="spacer"></div>
      <li class="bot-data">
        <div class="logo-container">
          <img
            class="botLogo"
            :src="$store.getters['page/picture']"
            :alt="$store.getters['page/name']"
          />
        </div>
        <div class="name-container">
          <span class="botName">{{ $store.getters["page/name"] }}</span>
        </div>
      </li>
    </ul>
    <ul class="submenu-options" id="submenu_1edw" :class="{ show: showSubMenu }">
      <li
        v-for="child in currentSubMenuOptions"
        :key="child.text"
        :class="{ selected: child.selected }"
        @click="onSubMenuOptionClicked(child)"
      >
        <span>{{ child.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { EventBus } from "../../utils/EventBus";
export default {
  data() {
    /**
     * Initializing the children of Chatbot subNav based on the Chatbot recipe
     */
    return {
      /**
       * To show the sideNav when user clicks on the burger icon
       */
      showSidenav: false,
      /**
       * SideNav options
       */
      menuOptions: [
        {
          to: "Dashboard.Commenter",
          icon: "comment-alt",
          text: "الإجابة على الأسئلة",
          selected: true
        },
        {
          to: "Dashboard.callUs",
          icon: "compass",
          text: "التواصل مع الصفحة",
          selected: false
        },
        {
          to: "chatbot.settings",
          icon: "icon-mujeeb-logo",
          text: "متجر مجيب",
          selected: false
        }
      ],
      /**
       * The current sub sideNav options if any.
       * Example: when clicking on 'mujeeb' option in the side nave a sub-menu will be shown.
       */
      currentSubMenuOptions: [],
      showSubMenu_: false
    };
  },
  mounted() {
    /**
     * This event listener is used to show the sidenave menu when user clicks on birger icon.
     */
    EventBus.$on("showSideNavBar", () => {
      this.showSidenav = !this.showSidenav;
    });
    document.addEventListener("click", this.clickOutSideSubMenu);
  },
  created() {
    EventBus.$on("TAB_SELECTED", this.onMainMenuOptionClicked);
    EventBus.$on("VIEW_SELECTED", this.deselectAllOptions);
  },
  computed: {
    /**
     * This computed property shows the options of the sideNav based on recipe of the chatbot and if there is one or not.
     */
    computed_options() {
      const recipe = this.$store.getters["page/botRecipe"];
      if (!recipe || recipe !== "customized") {
        return this.menuOptions;
      } else {
        return this.menuOptions.filter(
          option => option.to !== "Dashboard.callUs" && option.to !== "chatbot.settings"
        );
      }
    },
    /**
     * This computed property is used to show the subMenu if there is any.
     */
    showSubMenu() {
      return this.currentSubMenuOptions.length !== 0 && this.showSubMenu_;
    }
  },
  methods: {
    /*
     * Make every other option as not-selected
     */
    deselectOtherOptions(option) {
      this.showSidenav = !this.showSidenav;
      this.menuOptions.filter(op => op.to === option.to)[0].selected = true;
      this.menuOptions.map(op => {
        if (op.to !== option.to) {
          op.selected = false;
        }
      });
    },
    deselectAllOptions() {
      this.showSidenav = !this.showSidenav;
      this.menuOptions.map(op => {
        op.selected = false;
      });
    },
    /**
     * Change the state of the selected option 'option.selected' to TRUE
     * Check if the option has a children to render them as a SubNav menu
     */
    onMainMenuOptionClicked(option) {
      this.deselectOtherOptions(option);
      switch (option.to) {
        case "Dashboard.Commenter":
        case "Dashboard.Broadcast":
        case "Dashboard.callUs":
          this.currentSubMenuOptions.map(option => {
            option.selected = false;
          });
          this.currentSubMenuOptions = [];
          this.$router.push({ name: option.to }).catch(error => {
            console.log(error);
          });
          this.showSidenav = false;
          this.showSubMenu_ = false;
          break;
        case "chatbot.settings":
          const recipe = this.$store.getters["page/botRecipe"];
          if (recipe == null) {
            this.$router.push({ name: "chatbot.initialization" }).catch(error => {
              console.log(error);
            });
            this.showSidenav = false;
            break;
          }
          // if (this.currentSubMenuOptions.length === 0) {
          //   this.currentSubMenuOptions = this.$store.getters["recipesConfig/sideNavButtons"];
          // }
          this.currentSubMenuOptions = this.$store.getters["recipesConfig/sideNavButtons"];
          this.showSubMenu_ = !this.showSubMenu_;
          break;
        default:
          this.showSidenav = false;
          this.showSubMenu_ = false;
          break;
      }
    },
    SubMenu_deselectOtherOptions(option) {
      this.currentSubMenuOptions.filter(op => op.to === option.to)[0].selected = true;
      this.currentSubMenuOptions.map(op => {
        if (op.to !== option.to) {
          op.selected = false;
        }
      });
    },
    onSubMenuOptionClicked(option) {
      this.SubMenu_deselectOtherOptions(option);
      if (option.withParams) {
        switch (option.param) {
          case "pageId":
            this.$router
              .push({
                name: option.to,
                params: { pageId: this.$store.getters["page/id"] }
              })
              .catch(error => {
                console.log(error);
              });
            break;
        }
      } else {
        this.$router.push({ name: option.to }).catch(error => {
          console.log(error);
        });
      }
      this.showSubMenu_ = false;
      this.showSidenav = false;
    },
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    clickOutSideSubMenu(event) {
      const subMenu = document.getElementById("submenu_1edw");
      const sideNavElementsArray = document.querySelectorAll(".m-side-nav li");
      const subMenuTrigger = sideNavElementsArray[sideNavElementsArray.length - 2];
      if (
        this.isVisible(subMenu) &&
        !subMenu.contains(event.target) &&
        !subMenuTrigger.contains(event.target)
      ) {
        // clicking outside the subnav menu
        this.showSubMenu_ = false;
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSideSubMenu);
  }
};
</script>

<style lang="scss" scoped>
.m-side-nav {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 59px;
  order: 1;
  list-style-type: none;
  background: #0a0a0b;
  overflow: hidden;
  box-shadow: 2px 0 11px rgba(0, 0, 0, 0.26);
  height: calc(100vh - 50px);
  &.showSideNav {
    display: flex;
  }
  &:hover {
    overflow: visible;
    width: 220px;
  }
  .bot-data {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px;
    &:hover {
      cursor: auto !important;
      border-left-color: #0a0a0b !important;
    }
    .logo-container {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      .botLogo {
        width: 80%;
        height: 80%;
        border-radius: 50px;
      }
    }
    .name-container {
      width: 180px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      .botName {
        font-family: "Cairo", sans-serif;
        cursor: default !important;
      }
    }
  }
  li {
    cursor: pointer;
    width: 206px;
    height: 45px;
    margin: 6px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    // border-left: 3px solid #0A0A0B;
    text-align: left;
    padding-left: 12px;

    &:first-child {
      margin-top: 9px;
    }
    &:last-child {
      margin-bottom: 9px;
    }
    &.selected {
      background-color: #007afe;
      border-left-color: #007afe !important;
      border-radius: 9px;
      &:hover {
        background-color: #007afe;
        > svg,
        i {
          color: white;
        }
      }
      > svg,
      i {
        color: white;
      }
    }
    &:hover {
      // border-left: 3px solid #007AFE;
      // background: #25282E;
      > svg,
      i > span {
        color: white;
      }
    }
    &.bot-data {
      border-radius: 9px;
      background: rgba(29, 29, 29, 0.829);
    }
    svg,
    i {
      text-align: center;
      color: #989dac;
      width: 22px;
      height: 22px;
      transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    }
    span {
      color: white;
      transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
      flex: 1 0 auto;
      text-align: center;
    }
    .feature-name {
      user-select: none;
    }
  }
}

.submenu-options {
  display: none;
  position: absolute;
  height: 100%;
  background: #1a1a1a;
  width: 220px;
  top: 0px;
  left: 100%;
  z-index: 702;
  li {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 6px;
    height: 45px;
    margin-bottom: 0px;
    cursor: pointer;
    color: white;
    padding: 10px;
    text-align: right;
    border-radius: 10px;
    &.selected {
      background: dodgerblue;
      color: white;
      &:hover {
        background: dodgerblue;
        color: white;
      }
    }
    span {
      flex: 1 0 auto;
    }
    &:hover {
      background: #0a0a0b;
    }
    &:first-child {
      margin-top: 9px;
    }
  }
  &.show {
    display: block;
  }
}

@media only screen and (max-width: 550px) {
  .m-side-nav {
    display: none;
    li {
      width: 45px !important;
      svg,
      i {
        margin: 0;
      }
      &.bot-data {
        .name-container {
          display: none;
        }
      }
      .feature-name,
      .name-container {
        display: none;
      }
    }
    &:hover {
      overflow: hidden;
      width: 58px;
    }
  }
  .showSideNav {
    display: flex !important;
  }
}

@media only screen and (min-width: 551px) and (max-width: 749px) {
  .m-side-nav {
    width: 58px;
    li {
      width: 45px !important;
      svg,
      i {
        margin: 0;
      }
      &.bot-data {
        .name-container {
          display: none;
        }
      }
      .feature-name,
      .name-container {
        display: none;
      }
    }
    &:hover {
      overflow: hidden;
      width: 58px;
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 1199px) {
  .m-side-nav {
    li {
      width: 45px !important;
      svg,
      i {
        margin: 0;
      }
      &.bot-data {
        .name-container {
          display: none;
        }
      }
      .feature-name {
        display: none;
      }
      &:hover {
        border-radius: 9px;
        background: rgba(29, 29, 29, 0.829);
        width: 206px !important;
        .feature-name {
          display: block;
        }
      }
    }
    &:hover {
      overflow: hidden;
      li {
        width: 206px !important;
        .name-container {
          display: flex;
        }
        .feature-name {
          display: block;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .m-side-nav {
    overflow: visible;
    width: 220px;
    li {
      &:hover {
        border-radius: 9px;
        background: rgba(29, 29, 29, 0.829);
        .feature-name {
          display: block;
        }
      }
    }
  }
}
</style>
