<template>
  <div v-if="$route.name !== 'Dashboard.Notifications'" class="notifications-stack">
    <div v-if="!summarized">
      <snackbar
        v-for="notification in $store.getters['notifications/notifications']"
        v-if="notification.show && !notification.dismissed"
        :key="notification.id"
        :notification="notification"
      ></snackbar>
    </div>
    <snackbar v-else></snackbar>
  </div>
</template>

<script>
import snackbar from "./notification";
export default {
  components: {
    snackbar
  },
  data() {
    return {
      summarized: false
    };
  },
  watch: {
    "$store.getters.windowWidth": {
      immediate: true,
      handler: function(newVal) {
        this.summarized = newVal < 850 && this.$store.getters.notifications.length > 3;
      }
    }
  }
};
</script>

<style scoped>
.notifications-stack {
  position: fixed;
  bottom: 15px;
  right: 20px;
  z-index: 800;
  /* width: 300px; */
  padding: 5px;
}
</style>
